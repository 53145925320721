import { Box, Divider, Paper, Typography } from "@mui/material";
import { Button } from "@mui/joy";

import QRCode from "../assets/qrCode.gif";
import Logo from "../assets/app_icon.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * DownloadApp
 *
 * @returns {ReactElement} Returns the Download App component
 */
export default function DownloadApp() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "stretch",
        }}
      >
        <Box sx={{ flex: 1, textAlign: "left" }}>
          <Button
            onClick={() => navigate(-1)}
            startDecorator={<i className="fa-solid fa-chevron-left"></i>}
            variant="plain"
          >
            {t("Back")}
          </Button>
        </Box>
        <Box>
          <img src={Logo} style={{ height: 85, width: 85, borderRadius: 20 }} />
        </Box>
        <Typography variant="h4">
          {t("Download the ")}
          <br />
          {t("Driver mobile app")}
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {t("Available for free on the App Store and Play Store.")}
          <br />
          {t(
            "Receive push notifications on new work offers. Track your revenues."
          )}
        </Typography>
        <Button
          startDecorator={<i className="fa-brands fa-app-store"></i>}
          size="lg"
          color="neutral"
          fullWidth
          variant="outlined"
          onClick={() =>
            window.open(
              "https://apps.apple.com/us/app/archer-driver/id1168704062"
            )
          }
        >
          {t("Get it on the App Store")}
        </Button>
        <Button
          startDecorator={<i className="fa-brands fa-google-play"></i>}
          size="lg"
          color="neutral"
          fullWidth
          variant="outlined"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.whitearrow.driver&hl=en&gl=US"
            )
          }
        >
          {t("Get it on Google Play")}
        </Button>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: 150,
              alignItems: "center",
              gap: 2,
              mt: 4,
              mb: 4,
            }}
          >
            <Divider sx={{ flex: 1 }} /> <Typography>{t("OR")}</Typography>
            <Divider sx={{ flex: 1 }} />
          </Box>
        </Box>
        <Paper sx={{ p: 2 }} variant="outlined">
          <img src={QRCode} style={{ height: 150, width: 150 }} />
          <Typography>{t("Scan the QR Code")}</Typography>
        </Paper>
      </Paper>
    </Box>
  );
}

export const RouterConfig = {
  root: "/",
  aboutYou: "/about-you",
  createAccount: "/create-account",
  downloadApp: "/download",
  info: "/info",
  signin: "/signin",
  myAccount: "/my-account",
  code: "/code",
};

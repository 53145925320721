import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import { Helmet } from "react-helmet";
import { AppConfig } from "../environement";
import archerFavicon from "../assets/archer-favicon.png";
import whitearrowFavicon from "../assets/whiteArrow-favicon.png";

/**
 * Root
 *
 * @returns {ReactElement} Returns the root component
 */
export default function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  useEffect(() => {
    if (pathnames.length === 0) return navigate(RouterConfig.signin);
  }, [pathnames]);

  return (
    <Box
      sx={{
        pt: 4,
        textAlign: "center",
        maxWidth: 572,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <Helmet>
        <title>
          {AppConfig.variant === "archer" ? "Archer" : "White Arrow"}
        </title>
        {/* <link
          rel="icon"
          id="favicon"
          href={
            AppConfig.variant === "archer" ? whitearrowFavicon : archerFavicon
          }
        /> */}
      </Helmet>
      <Outlet />
    </Box>
  );
}

import { createSelector } from "@reduxjs/toolkit";
import { EMAIL } from "../../constant";
import { selectEmail } from "../signin/signinSelector";
import { AppState } from "../store";

const selectFirstName = (state: AppState) =>
  state.applicationState.signup.firstName;

export const firstNameSelector = createSelector(
  [selectFirstName],
  (firstName) => {
    return firstName;
  }
);

const selectSignup = (state: AppState) => state.applicationState.signup;

export const signupSelector = createSelector([selectSignup], (signup) => {
  return signup;
});

const selectOrganizationToken = (state: AppState) =>
  state.applicationState.signup.organizationToken;

export const organizationTokenSelector = createSelector(
  [selectOrganizationToken],
  (organizationToken) => {
    return organizationToken;
  }
);

const selectLastName = (state: AppState) =>
  state.applicationState.signup.lastName;

export const lastNameSelector = createSelector([selectLastName], (lastName) => {
  return lastName;
});

const selectVerificationCode = (state: AppState) =>
  state.applicationState.verificationCode;

export const verificationCodeSelector = createSelector(
  [selectVerificationCode],
  (verificationCode) => {
    return verificationCode;
  }
);

const selectLoading = (state: AppState) => state.applicationState.loading;

export const loadingSelector = createSelector([selectLoading], (loading) => {
  return loading;
});

export const signupValidSelector = createSelector(
  [selectFirstName, selectLastName, selectEmail, selectOrganizationToken],
  (firstName, lastName, email, token) => {
    return firstName && lastName && token && EMAIL.test(email);
  }
);

const selectErrorMessage = (state: AppState) =>
  state.applicationState.errorMessage;

export const errorMessageSelector = createSelector(
  [selectErrorMessage],
  (errorMessage) => {
    return errorMessage ?? "";
  }
);

import { userSignupSuccess } from "@nerdjs/account-kit";
import { apiErrorAction } from "@nerdjs/nerd-network";
import { createReducer } from "@reduxjs/toolkit";
import { setEmail } from "../signin/signinActions";
import {
  restartPL,
  setFirstName,
  setLastName,
  setLoading,
  setOrganizationToken,
  setVerificationCode,
  signupSuccess,
} from "./applicationActions";
import { ApplicationState } from "./applicationState";

/**
 * ApplicationState
 *
 * @returns {ApplicationState} Returns application state
 */
function initState(): ApplicationState {
  return {
    loading: false,
    signup: {
      firstName: "",
      lastName: "",
      email: localStorage.getItem("email") ?? "",
      organizationToken: "",
    },
    verificationCode: "",
    pnlRequested: false,
  };
}

export const applicationReducer = createReducer(initState(), (builder) => {
  builder.addCase(apiErrorAction, (state, action) => {
    state.loading = false;
    if (action.payload.error.status != 401)
      state.errorMessage = action.payload.error.description;
    return state;
  });
  builder.addCase(signupSuccess, (state) => {
    state.loading = false;
    state.pnlRequested = true;
    state.errorMessage = "";
    return state;
  });
  builder.addCase(setFirstName, (state, action) => {
    state.signup.firstName = action.payload.firstName;
    return state;
  });
  builder.addCase(setOrganizationToken, (state, action) => {
    state.signup.organizationToken = action.payload.organizationToken;
    return state;
  });
  builder.addCase(restartPL, (state) => {
    state.errorMessage = undefined;
    state.loading = false;
    state.pnlRequested = false;
    state.verificationCode = "";
    return state;
  });

  builder.addCase(setLastName, (state, action) => {
    state.signup.lastName = action.payload.lastName;
    return state;
  });
  builder.addCase(setLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setVerificationCode, (state, action) => {
    state.verificationCode = action.payload.verificationCode;
    return state;
  });
  builder.addCase(setEmail, (state, action) => {
    state.signup.email = action.payload.email;
    return state;
  });
});

import { CssVarsProvider } from "@mui/joy";
import {
  AppBar,
  CircularProgress,
  CssBaseline,
  Grid,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { getCurrentUser } from "@nerdjs/account-kit";
import {
  initNetworkAction,
  withNetworkCompletionDispatch,
} from "@nerdjs/nerd-network";
import { ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import archerLogo from "../../assets/logo.png";
import waLogo from "../../assets/whiteArrowGrey.png";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { useAppDispatch, useAppSelector } from "../hooks";
import styles from "./style.module.scss";
import { theme } from "../config/themeConfig";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const muiTheme = useTheme();
  const isDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const networkCompletionStack = useAppSelector(
    (state) => state.networkState.networkCompletionStack
  );
  const [requestPending, setRequestPending] = useState(true);
  const [requestUUID, setRequestUUID] = useState("");
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  useEffect(() => {
    if (
      requestUUID !== "" &&
      !networkCompletionStack.some((e) => e[requestUUID])
    ) {
      setRequestPending(false);
    }
  }, [requestUUID, networkCompletionStack]);

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
    setTimeout(() => {
      const uuid = withNetworkCompletionDispatch(dispatch, getCurrentUser());
      setRequestUUID(uuid);
    }, 1000);
  }, []);

  const isSignin = pathnames.indexOf("signin") >= 0;
  let mt = 0;
  if (!isSignin) {
    mt = 8;
    if (isDownSm) mt = 6;
  }

  return (
    <CssVarsProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: isDownSm ? "column" : "row",
        }}
      >
        <CssBaseline />
        {requestPending ? (
          <Grid className={styles.circularProgressContainer} container>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            {!isSignin ? (
              <AppBar
                sx={{
                  background: "white",
                  boxShadow: "none",
                  borderBottom: "solid 1px rgba(155,155,155,0.3)",
                }}
              >
                <Toolbar sx={{ justifyContent: "center" }}>
                  <img
                    style={{ height: 40 }}
                    src={AppConfig.variant === "archer" ? archerLogo : waLogo}
                  />
                </Toolbar>
              </AppBar>
            ) : (
              []
            )}
            <Box
              component="main"
              className={isSignin ? `${AppConfig.variant}canvas2` : `canvas`}
              sx={{
                flexGrow: 1,
                display: "flex",
                mt,
                justifyContent: "center",
              }}
            >
              <AppRouter />
            </Box>
          </>
        )}
      </Box>
    </CssVarsProvider>
  );
}

import { Alert, Button as JoyButton, Typography } from "@mui/joy";
import {
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  userRequestPLCode,
  userSignin,
  userSubmitPLCode,
} from "@nerdjs/account-kit";
import { motion } from "framer-motion";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import whiteArrowLogo from "../assets/whiteArrow.png";
import {
  archerPath,
  EMAIL,
  METHOD_PASSWORD,
  METHOD_PASSWORD_LESS,
  animationImages,
} from "../constant";
import { AppConfig } from "../environement";
import { shuffle } from "../helpers";
import CodeField from "../hooks/codeField/codeField";
import { RouterConfig } from "../hooks/config/routerConfig";
import { useAppSelector } from "../hooks/hooks";
import { LoadingOverlay } from "../hooks/loadingOverlay/loadingOverlay";
import {
  restartPL,
  setEmail,
  setLoading,
  setPassword,
  setPasswordVisible,
  setPnlRequested,
  setSigninMethod,
  setVerificationCode,
} from "../redux/signin/signinActions";
import {
  emailSelector,
  errorMessageSelector,
  loadingSelector,
  passwordSelector,
  passwordVisibleSelector,
  signinMethodSelector,
  verificationCodeSelector,
} from "../redux/signin/signinSelector";

const CODE_LENGTH = 6;

/**
 *
 * @returns {ReactElement} Signin route
 */
export default function Signin(): ReactElement {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useAppSelector(emailSelector);
  const password = useAppSelector(passwordSelector);
  const signinMethod = useAppSelector(signinMethodSelector);
  const passwordVisible = useAppSelector(passwordVisibleSelector);
  const verificationCode = useAppSelector(verificationCodeSelector);
  const loading = useAppSelector(loadingSelector);
  const errorMessage = useAppSelector(errorMessageSelector);
  const phone = useAppSelector(
    (s) => s.accountKitState.userState.plResponse?.phone
  );
  const pnlRequested = useAppSelector((s) => s.signinState.pnlRequested);
  const matchCodeRoute = useMatch(`${RouterConfig.signin}${RouterConfig.code}`);
  const searchParams = useSearchParams()[0];
  const code = searchParams.get("code");
  const hasError = errorMessage.length > 0;
  const { t } = useTranslation();

  useEffect(() => {
    if (code) {
      dispatch(setPnlRequested(true));
      dispatch(setVerificationCode(code));
      plSignin(code);
    }
  }, [code]);

  const getSigninCode = () => {
    dispatch(setLoading(true));
    dispatch(setPnlRequested(true));
    dispatch(userRequestPLCode({ email }));
  };

  const plSignin = (code: string) => {
    dispatch(setLoading(true));
    dispatch(userSubmitPLCode({ code }));
  };

  const signin = () => {
    dispatch(setLoading(true));
    dispatch(userSignin({ email, password }));
  };

  const getStepContent = () => {
    if (signinMethod == METHOD_PASSWORD_LESS || matchCodeRoute) {
      if (!pnlRequested && !matchCodeRoute) {
        return (
          <>
            <Typography level="h5" sx={{ fontWeight: 600 }}>
              {t("Signin")}
            </Typography>
            <TextField
              size="medium"
              placeholder="Email"
              autoFocus={!email}
              value={email}
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  getSigninCode();
                }
              }}
              error={hasError}
              onChange={(e) => dispatch(setEmail(e.target.value))}
            />
            {hasError ? <Alert color="danger">{errorMessage}</Alert> : []}
            <JoyButton
              autoFocus={email.length > 0}
              disabled={!EMAIL.test(email)}
              onClick={getSigninCode}
              sx={{ mb: 4 }}
            >
              {t("Get Signin Code")}
            </JoyButton>
            <Box
              sx={{
                textAlign: "center",
                position: "absolute",
                width: "100%",
                padding: "16px",
                bottom: 0,
                left: 0,
              }}
            >
              <Typography
                onClick={() => dispatch(setSigninMethod(METHOD_PASSWORD))}
                sx={{
                  cursor: "pointer",
                  opacity: 0.6,
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                level="body3"
              >
                {t("Use my password instead")}
              </Typography>
            </Box>
          </>
        );
      } else if (!loading) {
        return (
          <>
            <Typography level="h5" sx={{ fontWeight: 600 }}>
              {t("Enter the digit code:")}
              <br />
              {phone ? (
                <Typography level="body2">
                  {t("Sent to ")}
                  <b>****{phone}</b>
                  {t(" and ")}
                  <b>{email}</b>
                </Typography>
              ) : (
                <Typography level="body2">
                  {t("Sent to ")}
                  <b>{email}</b>
                </Typography>
              )}
            </Typography>
            <CodeField
              length={CODE_LENGTH}
              onChange={(e) => {
                if (e.length === CODE_LENGTH && !hasError) plSignin(e);
                dispatch(setVerificationCode(e));
              }}
              value={verificationCode}
              error={hasError}
            />
            {hasError ? <Alert color="danger">{errorMessage}</Alert> : []}
            {hasError ? (
              <JoyButton onClick={() => plSignin(verificationCode)}>
                {AppConfig.variant === "archer"
                  ? t("Go to Archer")
                  : t("Go to Portal")}
              </JoyButton>
            ) : (
              []
            )}
            <Box>
              <JoyButton
                startDecorator={<i className="fa-solid fa-chevron-left"></i>}
                size="sm"
                variant="plain"
                onClick={() => dispatch(restartPL())}
              >
                {t("Back")}
              </JoyButton>
            </Box>
          </>
        );
      }
    } else if (signinMethod == METHOD_PASSWORD) {
      return (
        <>
          <Typography level="h5" sx={{ fontWeight: 600 }}>
            {t("Signin")}
          </Typography>
          <TextField
            size="medium"
            placeholder="Email"
            autoFocus={!email}
            value={email}
            error={hasError}
            onChange={(e) => dispatch(setEmail(e.target.value))}
          />
          <TextField
            size="medium"
            placeholder="Password"
            autoFocus={email.length > 0}
            value={password}
            type={passwordVisible ? "text" : "password"}
            error={hasError}
            onKeyDown={(e) => {
              if (e.keyCode == 13) {
                signin();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() =>
                      dispatch(setPasswordVisible(!passwordVisible))
                    }
                    size="small"
                  >
                    {passwordVisible ? (
                      <i className="fa-solid fa-eye-slash"></i>
                    ) : (
                      <i className="fa-solid fa-eye"></i>
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(setPassword(e.target.value))}
          />
          {passwordVisible}
          {hasError ? <Alert color="danger">{errorMessage}</Alert> : []}
          <JoyButton
            disabled={!EMAIL.test(email) || !password}
            onClick={signin}
          >
            {AppConfig.variant === "archer"
              ? t("Go to Archer")
              : t("Go to Portal")}
          </JoyButton>
          <JoyButton
            color="success"
            fullWidth
            onClick={() => {
              if (EMAIL.test(email)) {
                getSigninCode();
              }
              setTimeout(
                () => dispatch(setSigninMethod(METHOD_PASSWORD_LESS)),
                200
              );
            }}
          >
            <Box>
              {t("Signin with a code")}
              <br />
              <Typography sx={{ color: "white" }} level="body4">
                {t("More Secure!")}
              </Typography>
            </Box>
          </JoyButton>
        </>
      );
    }

    return <Box />;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        p: 2,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {AppConfig.variant === "archer" ? (
          <Box sx={{ width: 224, height: 80 }}>
            <AnimatedLogo />
          </Box>
        ) : (
          <img style={{ height: 100 }} src={whiteArrowLogo} />
        )}
        <Paper
          sx={{
            width: "calc(100vw - 32px)",
            maxWidth: !sm ? 600 : undefined,
            position: "relative",
          }}
        >
          {loading ? <LoadingOverlay /> : []}

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Hidden smDown>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <MusiciansAnimation />
              </Grid>
            </Hidden>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "stretch",
                justifyContent: "center",
                position: "relative",
                pt: 4,
                pr: 4,
                pb: 4,
                pl: sm || md ? 4 : 0,
              }}
            >
              {getStepContent()}
            </Grid>
          </Grid>
        </Paper>
        <JoyButton
          size="sm"
          variant="soft"
          sx={{ fontWeight: 500 }}
          onClick={() => navigate(RouterConfig.aboutYou)}
        >
          {AppConfig.variant === "archer"
            ? t("Get Started with Archer")
            : t("Get Started with White Arrow")}
        </JoyButton>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography sx={{ color: "rgba(255,255,255,0.6)" }} level="body3">
          {t("© White Arrow ")}
          {new Date().getFullYear()}
          {t(". All Rights Reserved.")}
        </Typography>
      </Box>
    </Box>
  );
}

/**
 * AnimatedLogo
 *
 * @returns {ReactElement} Returns the Animated Archer Logo component
 */
function AnimatedLogo() {
  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      fill: "rgba(255,255,255, 0)",
      strokeWidth: 2,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      fill: "rgba(255,255,255, 1)",
      strokeWidth: 0,
    },
    a: {
      fill: "rgba(33, 150, 243, 1)",
      opacity: 1,
      pathLength: 1,
      strokeWidth: 0,
      stroke: "rgba(33, 150, 243,0.5)",
    },
  };

  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 80"
      className="item"
    >
      {archerPath.map((p, i) => (
        <motion.path
          d={p}
          key={p}
          variants={icon}
          initial="hidden"
          animate={i === 0 ? "a" : "visible"}
          transition={{
            default: { duration: 2, ease: "easeInOut" },
            fill: { duration: 2, ease: [1, 0, 0.8, 1] },
            strokeWidth: { duration: 2, ease: [1, 0, 0.8, 1] },
          }}
        />
      ))}
    </motion.svg>
  );
}

/**
 * MusiciansAnimation
 *
 * @returns {ReactElement} Returns the Musicians Animation component
 */
function MusiciansAnimation() {
  const [musicianImgs, setMusicianImgs] = useState<string[]>([]);
  const [index, setIndex] = useState(0);
  const [y, setY] = useState(0);
  const [opacity, setOpacity] = useState(1);

  let interval: NodeJS.Timeout;

  useEffect(() => {
    setMusicianImgs(shuffle(animationImages));
    if (!interval) {
      interval = setInterval(transition, 3000);
    }
    transition();

    return () => clearInterval(interval);
  }, []);

  const transition = () => {
    setY(0);
    setTimeout(() => {
      setOpacity(1);
    }, 300);

    setTimeout(() => {
      setOpacity(0);
      setY(-100);
    }, 2700);
    setIndex((i) => (i + 1) % animationImages.length);
  };

  return (
    <motion.div
      className="box"
      style={{ padding: 40 }}
      animate={{ y, opacity }}
      transition={{ type: "spring" }}
    >
      <img src={musicianImgs[index]} />
    </motion.div>
  );
}

import forklift from "./assets/archer_forklift.png";
import pallet from "./assets/archer_pallet.png";
import picker from "./assets/archer_picker.png";
import shelf from "./assets/archer_shelf.png";
import stacking from "./assets/archer_stacking.png";
import truck from "./assets/archer_truck.png";

export const METHOD_PASSWORD = "password";
export const METHOD_PASSWORD_LESS = "passwordLess";

export const CUSTOMER = "I'm a Customer";
export const DRIVER = "I'm a Truck Driver";
export const aboutYouOption = [CUSTOMER, DRIVER];

export const archerPath = [
  "M22.16,79.78l-22.16-.03L30.42,0h22.64L22.16,79.78Z",
  "M39.97,65.88h-12.38l6.11-15.77h6.27v15.77ZM53.09,.04l-10.29,26.56,8.24,23.51h-6.34v15.77h11.85l4.76,13.86h22.11L53.09,.04Z",
  "M136.04,78.66h-18.67l-8.53-22.76c-.08-.39-.23-.9-.43-1.53-.21-.63-.46-1.39-.77-2.28l.12,4.22v22.35h-17.18V14.76h17.67c7.78,0,13.6,1.53,17.46,4.59,4.91,3.92,7.37,9.49,7.37,16.72,0,7.73-3.3,13.06-9.89,16.02l12.83,26.57Zm-28.18-34.31h1.16c1.99,0,3.64-.76,4.97-2.28,1.32-1.52,1.99-3.45,1.99-5.79,0-4.77-2.5-7.16-7.49-7.16h-.62v15.23Z",
  "M176.72,59l-.99,18.46c-3.84,1.54-7.27,2.32-10.3,2.32-7.15,0-13.16-2.62-18.04-7.86-5.46-5.88-8.19-14.2-8.19-24.96,0-10.07,2.57-18.15,7.7-24.25,5.13-6.07,11.99-9.1,20.57-9.1,2.79,0,5.63,.52,8.53,1.57v18.25c-2.57-2.57-5.2-3.85-7.9-3.85-3.53,0-6.29,1.5-8.28,4.51-1.99,3.04-2.98,7.23-2.98,12.58,0,2.59,.25,4.91,.74,6.95,.5,2.04,1.21,3.77,2.13,5.17,.92,1.41,2.06,2.48,3.41,3.23,1.35,.74,2.9,1.12,4.64,1.12,2.98,0,5.97-1.38,8.98-4.14Z",
  "M227.67,78.66h-17.26v-25.7h-8.24v25.7h-17.26V14.76h17.26v22.6h8.24V14.76h17.26v63.9Z",
  "M236.61,78.66V14.76h32.07v15.02h-14.82v9.31h13.28v14.44h-13.28v9.77h14.82v15.35h-32.07Z",
  "M321.49,78.66h-18.67l-8.53-22.76c-.08-.39-.23-.9-.43-1.53-.21-.63-.46-1.39-.77-2.28l.12,4.22v22.35h-17.18V14.76h17.67c7.78,0,13.6,1.53,17.46,4.59,4.91,3.92,7.37,9.49,7.37,16.72,0,7.73-3.3,13.06-9.89,16.02l12.83,26.57Zm-28.18-34.31h1.16c1.99,0,3.64-.76,4.97-2.28,1.32-1.52,1.99-3.45,1.99-5.79,0-4.77-2.5-7.16-7.49-7.16h-.62v15.23Z",
];

export const animationImages = [
  forklift,
  pallet,
  picker,
  shelf,
  stacking,
  truck,
];

export const EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

import { Box, TextField, TextFieldProps } from "@mui/material";
import {
  ChangeEvent,
  createRef,
  KeyboardEvent,
  ReactElement,
  RefObject,
  useEffect,
  useState,
} from "react";

/**
 * NerdCodeField: A component that displays multiple inputs for a one-time-code.
 *
 * @param {{length, onChange, value, error, TextfieldProps}} params List of params
 * @returns {React.ReactElement} Returns the CodeField component
 */
export default function NerdCodeField({
  length = 6,
  onChange,
  value = "",
  error = false,
  TextfieldProps,
}: {
  length: number;
  onChange: (c: string) => void;
  value: string;
  error: boolean;
  TextfieldProps?: TextFieldProps;
}): ReactElement {
  const arrLength = length;
  const [elRefs, setElRefs] = useState<RefObject<HTMLInputElement>[]>([]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(arrLength)
        .fill(0)
        .map((_, i) => elRefs[i] || createRef())
    );
  }, [arrLength]);

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData("Text");
    e.stopPropagation();
    e.preventDefault();
    if (/^\d+$/.test(pastedValue)) {
      moveTo(length - 1);
      return onChange(pastedValue);
    }
  };

  const handleChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      const digit = e.target.value.trim(); // single digit keyboard input case
      if (digit === value[index]) return;
      if (/^\d$/.test(digit)) {
        onChange(
          value.substring(0, index) + digit + value.substring(index + 1)
        );
        moveToNext(index);
      } else if (digit === "") {
        onChange(value.substring(0, index) + " " + value.substring(index + 1));
      }
    };

  const moveTo = (index: number) => {
    elRefs[index].current?.focus();
  };

  const moveToNext = (index: number) => {
    if (index < length - 1) elRefs[index + 1].current?.focus();
  };

  const moveToPrevious = (index: number) => {
    if (index > 0) elRefs[index - 1].current?.focus();
  };

  const handleKeyUp = (t: number) => (e: KeyboardEvent<HTMLDivElement>) => {
    if (
      e.keyCode === undefined ||
      e.keyCode === 16 ||
      e.keyCode === 9 ||
      e.keyCode === 224 ||
      e.keyCode === 18 ||
      e.keyCode === 17
    ) {
      return;
    }

    if (e.keyCode == 39) {
      // keyboard right arrow
      moveToNext(t);
    } else if (e.keyCode == 37) {
      // keyboard left arrow
      moveToPrevious(t);
    } else if (e.keyCode == 8) {
      // delete
      moveToPrevious(t);
    } else if (/^\d$/.test(e.key) && e.key === value[t]) {
      moveToNext(t);
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {[...Array(length).keys()].map((t) => (
        <TextField
          placeholder="X"
          {...TextfieldProps}
          inputRef={elRefs[t]}
          key={t}
          autoFocus={t === 0}
          onFocus={(event) => {
            event.target.select();
          }}
          onKeyUp={handleKeyUp(t)}
          inputProps={{
            inputtype: "numeric",
            onPaste: t === 0 ? handleOnPaste : undefined,
            pattern: "[0–9]*",
            maxLength: 1,
            autocomplete: t === 0 ? "one-time-code" : "off",
            style: {
              textAlign: "center",
            },
          }}
          error={error}
          value={value.length >= t && value[t] !== " " ? value[t] : ""}
          onChange={handleChange(t)}
        />
      ))}
    </Box>
  );
}

export type AppConfigType = {
  redirectTo: string;
  api: AppConfigApiType;
  variant: "archer" | "whiteArrow";
  app: AppConfigAppType;
  companyInfo: AppConfigCompanyInfoType;
  theme: AppConfigThemeType;
  front: AppConfigFrontType;
};

type AppConfigFrontType = {
  hostname: string;
};

type AppConfigApiType = {
  endpoint: string;
  port: number;
  production: boolean;
  routePrefix: string;
  signinRoute: string;
  signoutRoute: string;
  currentUser: string;
};

type AppConfigAppType = {
  alphaWatermark: boolean;
  favicon: string;
  logo: string;
  logoDarkMode: string;
  notFoundImage: string;
  showPLSignin: boolean;
  showForgotPassword: boolean;
  showSignup: boolean;
  rootBackground: string;
  title: string;
};

type AppConfigCompanyInfoType = {
  contact: string;
  name: string;
  support: string;
  website: string;
};

type AppConfigThemeType = {
  primaryColor: string;
  secondaryColor: string;
};

declare global {
  interface Window {
    _APP_CONFIG: AppConfigType;
  }
}

window._APP_CONFIG = window._APP_CONFIG || {};

export const AppConfig = window._APP_CONFIG;

import translationEn from "./en/translation.json";
import translationFr from "./fr/translation.json";

const translations = {
  en: {
    translation: translationEn,
  },
  fr: {
    translation: translationFr,
  },
};

export default translations;

import { Box, CircularProgress } from "@mui/joy";
import { useEffect, useState } from "react";

/**
 * LoadingOverlay
 *
 * @returns {ReactElement} Blur conainer with a centered circular progress
 */
export function LoadingOverlay() {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setTimeout(() => setOpacity(1), 100);
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: "rgba(255,255,255,0.8)",
        zIndex: 99,
        opacity,
        transition: "opacity .3s ease-in; backdrop-filter .3s ease-in;",
        backdropFilter: `blur(${opacity * 10}px)`,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

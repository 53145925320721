import Signup_Entity from "./signup";
export default class Signup extends Signup_Entity {
  static fromList(signupsJSON: unknown): Array<Signup> {
    const signups: Signup[] = [];
    if (signupsJSON)
      Array.isArray(signupsJSON) &&
        signupsJSON.forEach((signupJSON) => {
          signups.push(new Signup(signupJSON));
        });
    return signups;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}

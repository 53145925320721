import { Box, BoxProps, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import CreateAccount from "./routes/createAccount";
import { useAppSelector } from "./hooks/hooks";
import NotFoundRoute from "./routes/notFoundRoute";
import Root from "./routes/root";
import Signin from "./routes/signin";
import AboutYou from "./routes/aboutYou";
import DownloadApp from "./routes/downloadApp";

const BoxWrapper = styled((props: { hideMarginTop?: boolean } & BoxProps) => (
  <Box {...props} />
))(({ theme, hideMarginTop }) => ({
  padding: "10px",
  marginTop:
    useMediaQuery(theme.breakpoints.down("sm")) || hideMarginTop
      ? "inherit"
      : "48px",
  flexGrow: 1,
}));

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  const currentUser = useAppSelector(
    (state) => state.accountKitState.userState.currentUser
  );
  return (
    <Routes>
      <Route path={RouterConfig.root} element={<Root />}>
        <Route path={RouterConfig.aboutYou} element={<AboutYou />}></Route>
        <Route
          path={RouterConfig.downloadApp}
          element={<DownloadApp />}
        ></Route>
        <Route
          path={RouterConfig.createAccount}
          element={<CreateAccount />}
        ></Route>
      </Route>
      <Route path={RouterConfig.signin} element={<Signin />}>
        <Route
          path={`${RouterConfig.signin}${RouterConfig.code}`}
          element={<Signin />}
        />
      </Route>
      <Route
        path={"*"}
        element={
          <BoxWrapper hideMarginTop={currentUser ? false : true}>
            <NotFoundRoute />
          </BoxWrapper>
        }
      />
    </Routes>
  );
}

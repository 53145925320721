import { PostAction } from "@nerdjs/nerd-network";
import { createAction, Dispatch, nanoid } from "@reduxjs/toolkit";
import { Signup } from "../../entities/signup";

export const setFirstName = createAction(
  "application/setFirstName",
  function prepare(firstName: string) {
    return {
      payload: {
        firstName,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setOrganizationToken = createAction(
  "application/setOrganizationToken",
  function prepare(organizationToken: string) {
    return {
      payload: {
        organizationToken,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setLastName = createAction(
  "application/setLastName",
  function prepare(lastName: string) {
    return {
      payload: {
        lastName,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVerificationCode = createAction(
  "application/setVerificationCode",
  function prepare(verificationCode: string) {
    return {
      payload: {
        verificationCode,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setLoading = createAction(
  "application/setLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const restartPL = createAction(
  "application/restartPL",
  function prepare() {
    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const signup = createAction(
  "service/signups/createSignup",
  function prepare(body: Partial<Signup>) {
    const request = new PostAction(`/customer-api/signup`, body);

    request.decodeResponse = (dispatch: Dispatch, json: unknown) => {
      const signup = new Signup(json);
      dispatch(signupSuccess(signup));
    };

    return {
      payload: {
        id: nanoid(),
        createdAt: new Date().toISOString(),
        request,
      },
    };
  }
);

export const signupSuccess = createAction(
  "service/signups/setSignup",
  function prepare(signup: Signup) {
    return {
      payload: {
        signup,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

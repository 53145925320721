import { createSelector } from "@reduxjs/toolkit";
import { EMAIL } from "../../constant";
import { AppState } from "../store";

export const selectEmail = (state: AppState) => state.signinState.email;

export const emailSelector = createSelector([selectEmail], (email) => {
  return email;
});

export const emailIsValidSelector = createSelector([selectEmail], (email) => {
  return email === "" || EMAIL.test(email);
});

const selectPassword = (state: AppState) => state.signinState.password;

export const passwordSelector = createSelector([selectPassword], (password) => {
  return password;
});

const selectErrorMessage = (state: AppState) => state.signinState.errorMessage;

export const errorMessageSelector = createSelector(
  [selectErrorMessage],
  (errorMessage) => {
    return errorMessage ?? "";
  }
);

const selectPasswordVisible = (state: AppState) =>
  state.signinState.passwordVisible;

export const passwordVisibleSelector = createSelector(
  [selectPasswordVisible],
  (passwordVisible) => {
    return passwordVisible;
  }
);

const selectSigninMethod = (state: AppState) => state.signinState.signinMethod;

export const signinMethodSelector = createSelector(
  [selectSigninMethod],
  (signinMethod) => {
    return signinMethod;
  }
);

const selectVerificationCode = (state: AppState) =>
  state.signinState.verificationCode;

export const verificationCodeSelector = createSelector(
  [selectVerificationCode],
  (verificationCode) => {
    return verificationCode;
  }
);

const selectLoading = (state: AppState) => state.signinState.loading;

export const loadingSelector = createSelector([selectLoading], (loading) => {
  return loading;
});

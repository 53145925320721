import { Alert, Button, Button as JoyButton, Typography } from "@mui/joy";
import {
  Box,
  Divider,
  Grid,
  Grow,
  Link,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { RouterConfig } from "../hooks/config/routerConfig";
import {
  setFirstName,
  setLastName,
  setLoading,
  setOrganizationToken,
  signup,
} from "../redux/application/applicationActions";
import {
  errorMessageSelector,
  firstNameSelector,
  lastNameSelector,
  loadingSelector,
  organizationTokenSelector,
  signupSelector,
  signupValidSelector,
} from "../redux/application/applicationSelector";
import { setVerificationCode } from "../redux/signin/signinActions";
import { verificationCodeSelector } from "../redux/signin/signinSelector";
import { setEmail } from "../redux/signin/signinActions";
import {
  emailIsValidSelector,
  emailSelector,
} from "../redux/signin/signinSelector";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppConfig } from "../environement";
import { useAppSelector } from "../hooks/hooks";
import { LoadingOverlay } from "../hooks/loadingOverlay/loadingOverlay";
import { NerdCodeField } from "@nerdjs/nerd-ui";
import { userSubmitPLCode } from "@nerdjs/account-kit";

const CODE_LENGTH = 6;

/**
 * CreateAccount
 *
 * @returns {ReactElement} Returns create cccount form.
 */
export default function CreateAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const pnlRequested = useAppSelector((s) => s.applicationState.pnlRequested);
  const signupBody = useAppSelector(signupSelector);
  const firstName = useSelector(firstNameSelector);
  const lastName = useSelector(lastNameSelector);
  const verificationCode = useSelector(verificationCodeSelector);
  const email = useSelector(emailSelector);
  const emailIsValid = useSelector(emailIsValidSelector);
  const organizationToken = useSelector(organizationTokenSelector);
  const loading = useAppSelector(loadingSelector);
  const errorMessage = useAppSelector(errorMessageSelector);
  const hasError = errorMessage.length > 0;
  const signupValid = useSelector(signupValidSelector);
  const { t } = useTranslation();
  const [creatingAccount, setCreatingAccount] = useState(true);

  useEffect(() => {
    if (pnlRequested) {
      setTimeout(() => {
        setCreatingAccount(false);
      }, 3000);
    }
  }, [pnlRequested]);

  const signupRequest = () => {
    dispatch(setLoading(true));
    dispatch(signup(signupBody));
  };

  const plSignin = (code: string) => {
    dispatch(setLoading(true));
    dispatch(userSubmitPLCode({ code }));
  };

  const getStepContent = () => {
    if (pnlRequested) {
      return (
        <Box
          sx={{
            display: "flex",
            // alignItems: "center",
            gap: 2,
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              startDecorator={
                creatingAccount && (
                  <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                )
              }
              level="h5"
              sx={{ fontWeight: 600 }}
            >
              {creatingAccount ? (
                "Creating Account..."
              ) : (
                <>
                  {AppConfig.variant === "whiteArrow" ? (
                    <Typography level="h6">
                      Welcome to our transportation management platform
                      <br /> - powered by Archer!
                    </Typography>
                  ) : (
                    <Typography level="h6">Welcome to Archer!</Typography>
                  )}
                </>
              )}
            </Typography>
          </Box>
          {creatingAccount ? (
            <Typography>Please wait, we are setting you up</Typography>
          ) : (
            <>
              {AppConfig.variant === "whiteArrow" ? (
                <Typography>
                  Here, you can run quotes, tender loads, tracking and tracing,
                  retrieve documents and invoices, and sign up for a wide
                  variety of live notification options, just to name a few
                  features, all at your convenience.
                  <br /> <br />
                  Feel free to use any of our available tools, and if you
                  can&apos;t locate any of the features you are looking for, or
                  you have any questions or feedback, please let us know by
                  emailing our customer service at{" "}
                  <Link href="mailto:cs@whitearrow.com">
                    cs@whitearrow.com
                  </Link>{" "}
                  We are just a click away. <br /> <br />
                  The Archer Team
                </Typography>
              ) : (
                <Typography>
                  This system was created to make your work easier, by bringing
                  all the information and the data you need to make efficient
                  operational decisions, straight to your fingertips.
                  <br /> <br /> Over the next few weeks, you will get to learn
                  the logic and the flow of the system, the features, and the
                  tools that Archer provides - nothing to be intimidated by. We
                  are confident you will love Archer as much as we do (and we
                  love Archer a lot). <br /> <br />
                  The Archer Team
                </Typography>
              )}
              <Divider />
              <Box sx={{ width: 300, margin: "auto" }}>
                <Typography level="body2" sx={{ color: "black", mb: 1 }}>
                  Enter the code you just received by email:
                </Typography>
                <NerdCodeField
                  length={CODE_LENGTH}
                  onChange={(e) => {
                    dispatch(setVerificationCode(e));
                    if (e.length === CODE_LENGTH && !hasError) plSignin(e);
                  }}
                  value={verificationCode}
                  error={hasError}
                />
              </Box>
              {hasError ? <Alert color="danger">{errorMessage}</Alert> : []}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                {!creatingAccount ? (
                  <Grow in>
                    <Button
                      onClick={() => plSignin(verificationCode)}
                      disabled={verificationCode.length !== CODE_LENGTH}
                      sx={{ width: "100%" }}
                    >
                      Access your account
                    </Button>
                  </Grow>
                ) : (
                  []
                )}
              </Box>
            </>
          )}
        </Box>
      );
    }
    return (
      <>
        <Box sx={{ flex: 1, textAlign: "left" }}>
          <JoyButton
            onClick={() => navigate(-1)}
            startDecorator={<i className="fa-solid fa-chevron-left"></i>}
            variant="plain"
          >
            {t("Back")}
          </JoyButton>
        </Box>
        <Typography level="body1" sx={{ mt: 1 }}>
          {AppConfig.variant === "archer"
            ? t("You will be guided to get started with Archer.")
            : t("You will be guided to get started with White Arrow.")}
        </Typography>
        <Typography sx={{ pb: 2, pt: 2 }} level="h4">
          {t("First, let us create your account")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              value={firstName}
              fullWidth
              onChange={(e) => dispatch(setFirstName(e.target.value))}
              label={t("First Name")}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={lastName}
              onChange={(e) => dispatch(setLastName(e.target.value))}
              fullWidth
              label={t("Last Name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              helperText={!emailIsValid ? "Email not valid" : ""}
              fullWidth
              onChange={(e) => dispatch(setEmail(e.target.value))}
              value={email}
              label={t("Email")}
              type="email"
              error={!emailIsValid}
            />
          </Grid>
          {AppConfig.variant === "whiteArrow" ? (
            <Grid item xs={12}>
              <TextField
                fullWidth
                onChange={(e) => dispatch(setOrganizationToken(e.target.value))}
                value={organizationToken}
                label={t("Customer Code")}
              />
            </Grid>
          ) : (
            []
          )}
          {hasError ? (
            <Grid item xs={12}>
              <Alert color="danger">{errorMessage}</Alert>
            </Grid>
          ) : (
            []
          )}
        </Grid>
        <JoyButton disabled={!signupValid} onClick={signupRequest}>
          {t("Create account")}
        </JoyButton>
      </>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{
          width: "calc(100vw - 32px)",
          maxWidth: !sm ? 600 : undefined,
          position: "relative",
        }}
      >
        {/* {JSON.stringify(signupBody)} */}
        {loading ? <LoadingOverlay /> : []}
        <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          {getStepContent()}
        </Box>
      </Paper>
      <Typography level="body1">
        {t("Already have an account? ")}
        <Link component={RouterLink} to={RouterConfig.signin}>
          {t("Sign in here.")}
        </Link>
      </Typography>
    </Box>
  );
}

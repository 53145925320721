import { accountKitReducer, AccountKitState } from "@nerdjs/account-kit";
import {
  networkMiddleware,
  networkReducer,
  NetworkState,
} from "@nerdjs/nerd-network";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { applicationReducer } from "./application/applicationReducer";
import { ApplicationState } from "./application/applicationState";
import { mainMiddleware } from "./mainMiddleware";
import { signinReducer } from "./signin/signinReducer";
import { SigninState } from "./signin/signinState";

export interface AppState {
  accountKitState: AccountKitState;
  networkState: NetworkState;
  applicationState: ApplicationState;
  signinState: SigninState;
}

export const store = createStore(
  combineReducers({
    // this defines the shape of our MainState
    accountKitState: accountKitReducer,
    applicationState: applicationReducer,
    networkState: networkReducer,
    signinState: signinReducer,
  }),
  applyMiddleware(mainMiddleware, networkMiddleware)
);

export type AppDispatch = typeof store.dispatch;

import { getCurrentUser, userSigninSuccess } from "@nerdjs/account-kit";
import { showNotification } from "@nerdjs/nerd-ui";
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from "redux";
import { AppConfig } from "../environement";
import { store } from "./store";

export const mainMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, unknown>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    // Do stuff
    console.log(`[mainMiddleware]: mainMiddleware sees action:`, api);
    console.log(action);

    if (action.type == userSigninSuccess && action.payload.currentUser) {
      const searchParams = new URLSearchParams(window.location.search);
      const redirectTo = searchParams.get("redirectTo");
      if (redirectTo) {
        try {
          const redirectToUrl = new URL(redirectTo);
          const waBaseUrl = new URL(AppConfig.front.hostname);
          if (redirectToUrl.hostname.endsWith(waBaseUrl.hostname))
            window.location.replace(redirectTo);
          else window.location.replace(window._APP_CONFIG?.redirectTo);
        } catch {
          window.location.replace(window._APP_CONFIG?.redirectTo);
          return;
        }
      } else window.location.replace(window._APP_CONFIG?.redirectTo);
    }

    if (action.type === "ApiErrorAction") {
      const {
        uuid,
        status,
        description = "",
        message = "",
      } = action.payload.error;

      if (status === 401) {
        if (
          action.payload.originAction.payload.request.url ===
          AppConfig.api.currentUser
        ) {
          store.dispatch(userSigninSuccess(undefined));
        } else {
          store.dispatch(getCurrentUser());
        }
      }

      if (
        action.payload.originAction.payload.request.url !==
        AppConfig.api.currentUser
      )
        store.dispatch(
          showNotification({
            notification: {
              uuid: uuid,
              title: `${status} - ${description}`,
              body: message,
              severity: status > 300 && status < 500 ? "warning" : "error",
              autohide: true,
            },
          })
        );
    }

    return next(action);
  };

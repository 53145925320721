import { Button, Grid } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppConfig } from "../environement";
import { RouterConfig } from "../hooks/config/routerConfig";

/**
 *
 * @returns {ReactElement} NotFound route
 */
export default function NotFoundRoute(): ReactElement {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Grid gap={10} container justifyContent={"center"} mt={10}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        <img src={AppConfig.app.notFoundImage} width={"45%"} />
      </Grid>
      <Grid item marginTop={"auto"}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "rgb(15, 15, 130)",
            "&:hover": {
              backgroundColor: "rgb(15, 15, 80)",
            },
          }}
          onClick={() => {
            navigate(RouterConfig.root);
          }}
        >
          {t("Back to home")}
        </Button>
      </Grid>
    </Grid>
  );
}

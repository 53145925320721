import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translations from "./locales";
import { i18nextPlugin } from "translation-check";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(i18nextPlugin)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: translations,
  });

if (i18n.services.formatter) {
  i18n.services.formatter.add("DATE", (value, lng) => {
    return new Date(value).toLocaleDateString(lng, {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  });
}

export default i18n;

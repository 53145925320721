import { createAction, nanoid } from "@reduxjs/toolkit";

export const setEmail = createAction(
  "signin/setEmail",
  function prepare(email: string) {
    return {
      payload: {
        email,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPassword = createAction(
  "signin/setPassword",
  function prepare(password: string) {
    return {
      payload: {
        password,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPasswordVisible = createAction(
  "signin/setPasswordVisible",
  function prepare(passwordVisible: boolean) {
    return {
      payload: {
        passwordVisible,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setSigninMethod = createAction(
  "signin/setSigninMethod",
  function prepare(signinMethod: string) {
    return {
      payload: {
        signinMethod,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setVerificationCode = createAction(
  "signin/setVerificationCode",
  function prepare(verificationCode: string) {
    return {
      payload: {
        verificationCode,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setLoading = createAction(
  "signin/setLoading",
  function prepare(loading: boolean) {
    return {
      payload: {
        loading,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setErrorMessage = createAction(
  "signin/setErrorMessage",
  function prepare(errorMessage: string) {
    return {
      payload: {
        errorMessage,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const setPnlRequested = createAction(
  "signin/setPnlRequested",
  function prepare(pnlRequested: boolean) {
    return {
      payload: {
        pnlRequested,
        id: nanoid(),
        createdAt: new Date().toISOString(),
      },
    };
  }
);

export const restartPL = createAction("restartPL", function prepare() {
  return {
    payload: {
      id: nanoid(),
      createdAt: new Date().toISOString(),
    },
  };
});

import { Button } from "@mui/joy";
import { Box, Link, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { aboutYouOption, DRIVER } from "../constant";
import { RouterConfig } from "../hooks/config/routerConfig";

/**
 * AboutYou
 *
 * @returns {ReactElement} Returns the about you form.
 */
export default function AboutYou() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 4,
        p: 2,
      }}
    >
      <Paper
        variant="outlined"
        sx={{ p: 3, display: "flex", flexDirection: "column", gap: 2 }}
      >
        <Typography variant="body1" sx={{ mt: 1 }}>
          {t("Before we start let us make sure you are in the right spot.")}
        </Typography>
        <Typography sx={{ pb: 2, pt: 2 }} variant="h4">
          {t("Tell us more about you")}
        </Typography>
        {aboutYouOption.map((t) => (
          <Button
            onClick={() => {
              if (t === DRIVER) {
                navigate(RouterConfig.downloadApp);
              } else {
                navigate(RouterConfig.createAccount);
              }
            }}
            key={t}
            variant="soft"
            size="lg"
          >
            {t}
          </Button>
        ))}
      </Paper>
      <Typography variant="body1">
        {t("Already have an account? ")}
        <Link component={RouterLink} to={RouterConfig.signin}>
          {t("Sign in here")}
        </Link>
      </Typography>
    </Box>
  );
}

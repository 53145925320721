/**
 * Request_Entity type guard.
 *
 * @param {any} requestJson Request object from API
 * @returns {boolean} Return true if type is Request_Entity
 */
function validator(requestJson: unknown): requestJson is Request_Entity {
  if (typeof requestJson === "object" && requestJson != null) {
    Request_Entity.requiredFields.forEach((element) => {
      if (!Object.keys(requestJson).includes(element))
        console.log("Field ${element} is null or undefined");
    });
  }
  return true;
}

export default class Request_Entity {
  static requiredFields = ["id"];

  constructor(requestJson: unknown) {
    if (validator(requestJson)) {
      this.id = requestJson.id;
      this.firstName = requestJson.firstName;
      this.lastName = requestJson.lastName;
      this.email = requestJson.email;
      this.phone = requestJson.phone;
      this.organizationID = requestJson.organizationID;
      this.organizationToken = requestJson.organizationToken;
      this.inviteToken = requestJson.inviteToken;
      this.defaultAppID = requestJson.defaultAppID;
      this.active = requestJson.active;
      this.uuid = requestJson.uuid;
      this.icon = requestJson.icon;
      this.text = requestJson.text;
    } else {
      throw new Error(
        "Failed to create new instance of ${ Request_Entity.name }: ${e}"
      );
    }
  }

  id: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  organizationID?: number;
  organizationToken?: string;
  inviteToken?: string;
  defaultAppID?: number;
  active?: boolean;
  uuid?: string;
  icon?: string;
  text?: string;
}

import { userRequestPLCode } from "@nerdjs/account-kit";
import { apiErrorAction } from "@nerdjs/nerd-network";
import { createReducer } from "@reduxjs/toolkit";
import { METHOD_PASSWORD_LESS } from "../../constant";
import {
  restartPL,
  setEmail,
  setErrorMessage,
  setLoading,
  setPassword,
  setPasswordVisible,
  setPnlRequested,
  setSigninMethod,
  setVerificationCode,
} from "./signinActions";
import { SigninState } from "./signinState";

/**
 * SigninState
 *
 * @returns {SigninState} Returns signin state
 */
function initState(): SigninState {
  return {
    email: localStorage.getItem("email") ?? "",
    passwordVisible: localStorage.getItem("passwordVisible") === "true",
    password: "",
    pnlRequested: false,
    verificationCode: "",
    loading: false,
    signinMethod: localStorage.getItem("signinMethod") ?? METHOD_PASSWORD_LESS,
  };
}

export const signinReducer = createReducer(initState(), (builder) => {
  builder.addCase(apiErrorAction, (state, action) => {
    if (action.payload.originAction.type == userRequestPLCode) {
      state.pnlRequested = false;
    }
    state.loading = false;
    if (action.payload.error.status != 401)
      state.errorMessage = action.payload.error.description;
    return state;
  });
  builder.addCase(setEmail, (state, action) => {
    state.email = action.payload.email;
    localStorage.setItem("email", action.payload.email);
    return state;
  });
  builder.addCase(setPasswordVisible, (state, action) => {
    state.passwordVisible = action.payload.passwordVisible;
    localStorage.setItem(
      "passwordVisible",
      action.payload.passwordVisible.toString()
    );
    return state;
  });
  builder.addCase(setPassword, (state, action) => {
    state.password = action.payload.password;
    return state;
  });
  builder.addCase(setErrorMessage, (state, action) => {
    state.errorMessage = action.payload.errorMessage;
    return state;
  });
  builder.addCase(setLoading, (state, action) => {
    state.loading = action.payload.loading;
    return state;
  });
  builder.addCase(setSigninMethod, (state, action) => {
    state.signinMethod = action.payload.signinMethod;
    state.errorMessage = "";
    state.pnlRequested = false;
    localStorage.setItem("signinMethod", action.payload.signinMethod);
    return state;
  });
  builder.addCase(setVerificationCode, (state, action) => {
    state.verificationCode = action.payload.verificationCode;
    return state;
  });
  builder.addCase(setPnlRequested, (state, action) => {
    state.pnlRequested = action.payload.pnlRequested;
    return state;
  });
  builder.addCase(userRequestPLCode, (state) => {
    state.loading = false;
    state.signinMethod = METHOD_PASSWORD_LESS;
    localStorage.setItem("signinMethod", METHOD_PASSWORD_LESS);
    return state;
  });
  builder.addCase(restartPL, (state) => {
    state.pnlRequested = false;
    state.verificationCode = "";
    state.errorMessage = "";
    return state;
  });
});
